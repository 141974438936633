import React from 'react';
import styled from 'styled-components';
import logo from '../images/logo.png';
import SEO from '../components/seo';
import { GridCell, Grid, GridProps } from '@rmwc/grid';
import { Button, ButtonProps } from '@rmwc/button';
import { ComponentProps, MergeInterfacesT } from '@rmwc/types';

const TypedGrid = Grid as React.FC<MergeInterfacesT<GridProps, ComponentProps>>;
const TypedButton = Button as React.FC<
  MergeInterfacesT<ButtonProps, ComponentProps>
>;
const Layout = styled(TypedGrid)`
  padding: 4rem;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
`;

const Logo = styled('img')`
  max-height: 10rem;
  object-fit: contain;
`;
const ContactButton = styled(TypedButton)`
  color: white;
  margin-right: 1rem;
  margin-bottom: 1rem;
`;
const List = styled('ul')`
  border-left: 2px solid white;
  margin: 0;
  padding-left: 1rem;
  list-style-type: none;
  /* margin: 0.25rem; */
  li {
    margin: 0;
  }
`;

const IndexPage = () => (
  <Layout>
    <GridCell span={6}>
      <SEO title="Interactive Creative Agency | Mahon Studios" />
      <Logo src={logo} alt="Mahon Studios" />
      <p>
        Mahon Studios is a full service interactive creative agency. We are
        makers at heart, and with over 25 years of experience across a broad
        array of disciplines, Mahon Studios has the skill and motivation to help
        you with your vision.
      </p>
      <p>
        <strong>We want to listen to your ideas...</strong>
        <br />
        <strong>You'll want to listen to ours.</strong>
      </p>
      <p>
        We'll be launching our new site in the coming weeks.
        <br />
        In the meantime, call or email us so we can discuss your next project.
      </p>
      <ContactButton
        icon="phone"
        outlined
        tag="a"
        href="tel:3308545347"
        label="(330) 854-5347"
      />
      <ContactButton
        icon="email"
        outlined
        tag="a"
        href="mailto:info@mahonstudios.com"
        label="Email Us"
      />
      <p>453 Etheridge Blvd. S, Canal Fulton OH 44614</p>
      <List>
        <li>Photography &amp; Videography</li>
        <li>Print &amp; Outdoor Media</li>
        <li>Copywriting</li>
        <li>Graphic Design</li>
        <li>Illustration</li>
        <li>3D Modeling, Animation, and Virtual Reality</li>
        <li>Mobile, Desktop, and Web Application Development</li>
        <li>Interactive Physical Computing</li>
        <li>3D Printing &amp; CNC Tooling</li>
        <li>Set, Exhibit, and Prop Design</li>
      </List>
    </GridCell>
  </Layout>
);

export default IndexPage;
